import { useEffect, useState } from "react";
import ErrorMessage from "../../../components/common/ErrorMessage";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { rentSvc } from "../../../services";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CancelButton from "../../../components/button/cancelButton";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { InputNumber } from 'primereact/inputnumber';
import { Steps } from "primereact/steps";
import Invoice from "./invoice";
import './return.css'

const ReturnModal = ({ setVisible, visible = false, viewOnly = true, rent_Id, bill_Id, updateTableData }: any) => {
    const [t] = useTranslation();
    const [toolData, setToolData] = useState<any[]>([]);
    const [rentId, setRentId] = useState<string>(rent_Id);// to show return items
    const [billId, setBillId] = useState<string>(bill_Id);// To show bill amount & bill
    const [errors, setErrors] = useState<any>({});
    const [viewBill, setViewBill] = useState(false);
    const [totalAmnt, setTotalAmnt] = useState<any>(""); // View the amount to pay
    const [billAmnt, setBillAmnt] = useState<any>("");
    const [stage, setStage] = useState(0);
    let error: any = null;

    const items = [
        {
            label: 'Return'
        },
        {
            label: 'Payments'
        }
    ];

    const details = (rentId: string) => {
        rentSvc.getDetails(rentId).then(res => {
            const tools = res.data.toolData.map((tool: any) => ({
                ...tool,
                // return_quantity: tool.quantity - tool.returned_quantity
                return_quantity: 0

            }));
            setToolData(tools);
        });
    };



    useEffect(() => {
        if (billId) {
            getBillAmount(billId);
            setStage(1);
        }
        if (rentId)
            details(rentId);
    }, [billId, rentId]);



    const getBillAmount = (billId: string) => {
        rentSvc.getBillAmount(billId).then(res => {
            setTotalAmnt(res.data.rent_amnt)
        });
    };
    const payBillAmnt = async () => {

        if (billId) {
            const request = {
                _id: billId,
                rent_amnt: billAmnt
            }
            const res = await rentSvc.payBillAmnt(request);
            if (res)
                toast.success("Payment Success");
            setVisible(false)
            updateTableData()
            return;
        }
    };

    const onHide = () => {
        setVisible(false);
    };

    const handleReturn = async (event: any) => {
        event.preventDefault();
        const newErrors: any = {};
    
        let hasInvalidItems = false;
        let allZeroReturnQuantities = true;//To prevent return if all return quantity are zero
    
        const returnItems = toolData.map((item: any) => {
            if (item.return_quantity !== 0) {
                allZeroReturnQuantities = false;
    
                if (item.return_quantity < 0 || item.return_quantity > item.quantity - item.returned_quantity || item.return_quantity === null) {
                    newErrors[item._id] = "Enter the correct return qty";
                    hasInvalidItems = true;
                }
    
                return {
                    rent_id: item._id,
                    tool_id: item.tool_id._id,
                    return_quantity: item.return_quantity
                };
            }
            return null;
        }).filter(Boolean);
    
        if (allZeroReturnQuantities) {
            toast.warning("No items selected for return.");
            return;
        }
    
        if (hasInvalidItems) {
            setErrors(newErrors);
            return;
        }
    
        try {
            const res = await rentSvc.returnItems(rentId, returnItems);
            setBillId(res.data._id);
            toast.success("Items returned successfully");
            updateTableData();
            getBillAmount(res.data._id);
            setStage(prevStage => prevStage + 1); // Go to payment page
        } catch (error) {
            toast.error("Failed to return items");
        }
    };

    const DialogFooter = (
        <div className="flex flex-wrap justify-content-end gap-3">
            <CancelButton label={t("Cancel") + ""} onClose={onHide} />
            {/* <Button label={t("Prev") + ''} type="button" icon="pi pi-angle-double-left" className="p-button-text p-button-raised" onClick={() => { setStage(pre => pre - 1); }} hidden={stage === 0} /> */}
            <Button label={"Return"} icon="pi pi-check" className="p-button-success p-button-raised" style={{ backgroundColor: '#11B3CF' }} onClick={handleReturn} hidden={stage === 1}  />
            {/* <Button label={t("Next") + ''} type="button" icon="pi pi-angle-double-right" className="p-button-text p-button-raised" iconPos="right" onClick={() => { setStage(pre => pre + 1); getBillAmount(bill_Id) }} hidden={stage === 1} /> */}
        </div>
    );

    return (
        <>
            <Dialog header="Return" visible={visible} style={{ width: "50vw" }} onHide={onHide}>
                <Steps model={items} className="my-3" activeIndex={stage} />

                {stage === 0 && <form onSubmit={handleReturn}>
                    <div className="col-12">
                        <DataTable
                            value={toolData}
                            className="datatable-responsive white returnlist-datatable"
                            emptyMessage="No data found."
                            responsiveLayout="scroll"
                        >

                            <Column className="capitalize" style={{ flexGrow: 1, flexBasis: '200px' }} header="Item Name" field="tool_id.tool_name" ></Column>
                            <Column className="capitalize" style={{ flexGrow: 1, flexBasis: '200px' }} header="Rented Qty" field="quantity" ></Column>
                            <Column className="capitalize" style={{ flexGrow: 1, flexBasis: '200px' }} header="Balance Return Qty" body={(rowData: any) => (<>{rowData.quantity - rowData.returned_quantity}</>)} ></Column>
                            <Column
                                className="capitalize"
                                style={{ flexGrow: 1, flexBasis: '200px' }}
                                header="Return Qty"
                                body={(rowData: any) => (
                                    <>
                                        <InputNumber className="returnQuantity"
                                            value={rowData.return_quantity}
                                            onChange={(e) => {
                                                rowData.return_quantity = e.value;
                                                setToolData([...toolData]);
                                                const newErrors = { ...errors };
                                                if (Number(e.value) > rowData.quantity - rowData.returned_quantity) {
                                                    newErrors[rowData._id] =
                                                        "Return qty cannot be greater than the Balance qty";
                                                } else {
                                                    delete newErrors[rowData._id];
                                                }
                                                setErrors(newErrors);
                                            }}
                                            min={0}
                                        />
                                        <div>
                                            {errors[rowData._id] && <ErrorMessage message={errors[rowData._id]} id={"returnQuantity"} />}
                                        </div>
                                    </>
                                )}
                            />
                        </DataTable>
                    </div>

                </form>}
                {stage === 1 &&
                    <div className="flex flex-column gap-2 mb-4" >
                        <h6>Bill Amount:{totalAmnt ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', }).format(totalAmnt) : 'N/A'}</h6>
                        <label htmlFor="billAmnt">Enter the amount</label>
                        <div className="flex flex-row">
                            <InputNumber placeholder="INR 00.00" inputId="currency-india" value={billAmnt} onChange={(e) => setBillAmnt(e.value)} mode="currency" currency="INR" currencyDisplay="code" className={`${error ? 'p-invalid' : ''}`} />

                            {/* <InputText id="billAmnt" type="number" aria-describedby="billAmnt-help"  onChange={(e) => setBillAmnt(e.target.value)} className={`${error ? 'p-invalid' : ''}`}/> */}
                            <Button label={t("Pay") as string} icon="pi pi-check" className="p-button-success p-button-raised ml-3" onClick={payBillAmnt} />
                            <Button label={t("View Bill") as string} icon="pi pi-info-circle" className="p-button-primary p-button-raised ml-3" onClick={() => setViewBill(true)} />
                        </div>
                        {error && <ErrorMessage id={"category_name-help"} message="role required" />}
                    </div>}
                {DialogFooter}
            </Dialog>

            {viewBill && <Invoice id={billId} setVisible={setViewBill} visible={viewBill} />}
        </>
    );
};

export default ReturnModal;
