import { axiosInstance } from '../interceptors/interceptors';


export const save = async (request: any) => {
  console.log(request)
  return await axiosInstance.post(`/users/`, request);
};

export const list = async (limit: string, page: string) => {
  return await axiosInstance.get(`/users/list?page=${page}&limit=${limit}`);
};

export const get = async (id:string) => {
  return await axiosInstance.get(`/users/${id}`);
};

export const update = async (id: string, request: any) => {
  return await axiosInstance.put(`/users/${id}`,request)
};

export const serchUser = async (name:any) => {
  return await axiosInstance.get(`/users/user/search?name=${name}`);
};

export const statusUpdate = async (id: string, request: any) => {
  return await axiosInstance.put(`/users/setStatusById/${id}`,{status:request})
};

export const Delete = async (id:string) => {
  return await  axiosInstance.delete(`/users/${id}`);
}
export const multipleDelete = async (idList:any) => {
  return await  axiosInstance.post(`/users/deleteByIdList/`,idList);
}
