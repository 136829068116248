import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import permissionsReducer from '../reducers/permissionsReducer';
import rootSaga from '../saga/permissionSaga';

const sagaMiddleware = createSagaMiddleware();

const initialState = {
  permissions: null,
};
const rootReducer = combineReducers({
  permissions: permissionsReducer,
});

const store = createStore(
  rootReducer,
//   initialState,
  applyMiddleware(sagaMiddleware),
);

sagaMiddleware.run(rootSaga);

export default store;
