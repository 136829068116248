import { axiosInstance } from '../interceptors/interceptors';


export const list = async (limit: Number,page: Number) => {
    return await axiosInstance.get(`master/category/list?page=${page}&limit=${limit}`);
};
export const get = async (id: string) => {
    return await axiosInstance.get(`master/category/${id}`);
};
export const getAll = async () => {
    return await axiosInstance.get(`master/category/getAll`);
};
export const save = async (req: any) => {
    return await axiosInstance.post(`master/category`, req);
};

export const update = async (category_name: string, id: string) => {
    return await axiosInstance.put(`master/category/${id}`, { category_name });
};
export const updateRolePermiisions = async (req:any) => {
    return await axiosInstance.post(`admin/role/updatePermission`,req);
};
export const Delete = async (id:string) => {
    return await axiosInstance.delete(`master/category/delete/${id}`);
};
export const multipleDelete = async (idList:any) => {
    return await  axiosInstance.post(`master/category/deleteByIdList`,idList);
  }