import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import  {roleSvc} from '../../../../services';


const RoleModal = ({ setVisible, visible = false, viewOnly = true, id,updateTableData }: IRoleModal) => {
    const [roleId] = useState(id);
    const [name, setName] = useState("");
    const [roleError, setRoleError] = useState('');

    useEffect(() => {
        if (id) {
            roleSvc.get(id).then(res => {
                setName(res.data.name);
            });
        }
    }, [id]);
    const onSubmit = async () => {
        if (!name) {
            setRoleError('Role is required');
            return;
          }
          setRoleError('');
        if (roleId) {
            const res = await roleSvc.update(name, roleId);
            if (res) toast.success("Updated");
            setVisible(false)
            updateTableData()
            return;
        }
        const res = await roleSvc.save({name});
            if (res) toast.success("Created");
            updateTableData()
            setVisible(false)
            return;

    };
    const DialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger" onClick={() => {setVisible(false) }} />
            <Button label={id ? 'Update' : 'Save'} icon="pi pi-check" style={{ backgroundColor: '#11B3CF'}} className="p-button-success" onClick={onSubmit} />
        </>);
    return (
        <Dialog header={id ? 'Update Role' : 'Add Role'} footer={DialogFooter} visible={visible} maximizable style={{ width: '50vw' }} onHide={() => setVisible(false)}>
            <div className="flex flex-column gap-2" style={{ maxWidth: 400 }}>
                <label htmlFor="role">Role Name</label>
                <InputText id="role" value={name} aria-describedby="role-help" onChange={(e) => {setName(e.target.value);setRoleError('');}} className={`${roleError ? 'p-invalid' : ''}`} />
                {roleError && <ErrorMessage id="category_name-help" message={roleError} />}
            </div>
        </Dialog>
    );
};

export default RoleModal;



interface IRoleModal {
    visible: boolean;
    setVisible(val: boolean): void;
    viewOnly: boolean,
    id: string | null;
    updateTableData:any;
}