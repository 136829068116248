import { useEffect, useState } from "react";
import { rentSvc } from "../../../services";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import logo from '../../../assets/comptivia.png'
import moment from "moment";
import { formatCurrency } from "../../../components/styledcomponents";


const Invoice=({ setVisible, visible = false,  id }: any)=>{
    const [invoiceData, setInvoiceData] = useState<any>('');
    const [items, setItems] = useState<any>([]);
    const getBillData = (rentId: string) => {
        rentSvc.getBillData(rentId).then(res => {
            setInvoiceData(res.data)
            setItems(res.data.tools_data)
        });
    };
useEffect(() => {
    getBillData(id)
  }, [id]);
  const filteredItems = items.filter((item: any) => item.return_quantity !== 0);
return(
    <>
    <Button label="Show" icon="pi pi-external-link" onClick={() => setVisible(true)} />
    <Dialog  visible={visible} style={{ width: '70vw' }} onHide={() => setVisible(false)}>
    <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <div className="invoice-title">
              <h4 className="float-end font-size-15">
                Rent No :{invoiceData.rentdetails_id?.rent_no || "N/A"}{' '}
                <span className="capitalize badge bg-success font-size-12 ms-2">
                  {invoiceData.status}
                </span>
              </h4>
              <div className="mb-4">
                {/* <h2 className="mb-1 text-muted">{"Comptivia"}</h2> */}
                <img src={logo} alt="logo" height="50"   className="mb-3" />
              </div>
              <div className="text-muted">
                <p className="mb-1">{invoiceData.companyAddress}</p>
                <p className="mb-1">
                <i className="pi pi-envelope mr-2" style={{ color: "red" }} />
                  {"comptivia@gmail.com"}
                </p>
                <p>
                <i className="pi pi-phone mr-2" style={{ color: "red" }} />
                  {"+91 9876543210"}
                </p>
              </div>
            </div>

            <hr className="my-4" />

            <div className="row">
              <div className="col-sm-6">
                <div className="text-muted">
                  <h5 className="font-size-16 mb-3">Billed To:</h5>
                  <h5 className="font-size-15 mb-2">
                  {invoiceData.customer_id?.first_name || 'N/A'} {invoiceData.customer_id?.last_name || ''}
                  </h5>
                  <p className="mb-1">{invoiceData.address}</p>
                  <p className="mb-1"><i className="pi pi-envelope mr-2" /> {invoiceData.customer_id?.email ||"N/A"}</p>
                  <p><i className="pi pi-phone mr-2" />{invoiceData.customer_id?.phone||"N/A"}</p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="text-muted text-sm-end">
                  
                  <div className="mt-4">
                    <h5 className="font-size-15 mb-1">Invoice Date:</h5>
                    <p>{invoiceData?moment(invoiceData.generated_at).format('ll'): 'N/A'}</p>
                  </div>
                  <div className="mt-4">
                    <h5 className="font-size-15 mb-1">Order No:</h5>
                    <p>{"#"+invoiceData.rentdetails_id?.rent_no || "N/A"}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="py-2">
              <h5 className="font-size-15">Order Summary</h5>

              <div className="table-responsive">
                <table className="table align-middle table-nowrap table-centered mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: '70px' }}>No.</th>
                      <th>Item</th>
                      <th>Qty</th>
                      <th>Price</th>
                      <th className="text-end" style={{ width: '120px' }}>
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredItems.map((item:any, index:any) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          <div>
                            <h5 className="text-truncate font-size-14 mb-1">
                              {item.tool_id.tool_name}
                            </h5>
                            <p className="text-muted mb-0">{item.description}</p>
                          </div>
                        </td>
                        <td>{item.return_quantity}</td>
                        <td>{formatCurrency((item.rent)/(item.return_quantity))}</td>
                        <td className="text-end">{formatCurrency(item.rent)}</td>
                      </tr>
                    ))}
                    <tr>
                      <th scope="row" colSpan={4} className="text-end">
                        Sub Total
                      </th>
                      <td className="text-end">{formatCurrency(invoiceData.rent_amnt)}</td>
                    </tr>
                    
                    <tr>
                      <th scope="row" colSpan={4} className="border-0 text-end">
                        Total
                      </th>
                      <td className="border-0 text-end">
                        <h4 className="m-0 fw-semibold">{formatCurrency(invoiceData.rent_amnt)}</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-print-none mt-4">
                <div className="d-print-none mt-4">
                    <div className="float-end">
                    <Button label={"Print"}onClick={() => window.print()} className="btn btn-success me-1"/>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </Dialog>
  </>)
}
export default Invoice