export const model = [
    {
        // label: 'reports',
        items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/app' }],
        seperator: false
    },
    {
        // label: 'pages',
        icon: 'pi pi-fw pi-briefcase',
        to: '/app',
        items: [
            
            {
                label: 'Customers',
                icon: 'pi p-fw pi-users',
                to: '/app/customers'
            },
            {
                label: 'Inventory',
                icon: 'pi pi-building',
                to: '/pages/items',
                items: [

                    {
                        label: 'Items',
                        icon: 'pi pi-shopping-cart',
                        to: '/app/items',
                    },
                    {
                        label: 'Stock',
                        icon: 'pi pi-table',
                        to: '/app/stocks'
                        
                    },
                    {
                        label: 'Maintenance',
                        icon: 'pi pi-wrench',
                        to: '/app/maintenance'
                        
                    },
                ]
            },
            {
                label: 'Transactions',
                icon: 'pi pi-f pi-arrow-right-arrow-left',
                to: '/pages/items',
                items: [

                    {
                        label: 'Rent',
                        icon: 'pi pi-shopping-cart',
                        to: '/app/rents',
                    },
                    {
                        label: 'Returns & Bills',
                        icon: 'pi pi-replay',
                        to: '/app/returns'
                        
                       
                    },
                ]
            },
            ,
            {
                label: 'Admin Center',
                icon: 'pi pi-cog',
                to: '/pages/items',
                items: [
                    {
                        label: 'Users',
                        icon: 'pi p-fw pi-user',
                        to: '/app/users',
                    },
                    {
                        label: 'Admin Panel',
                        icon: 'pi pi-cog',
                        to: '/app/admin'
                        
                       
                    },
                ]
            },
            
        ]
    },
];
