import { axiosInstance } from '../interceptors/interceptors';


export const save = async (request: any) => {
    return await axiosInstance.post(`/tools/maintanance/`, request);
};

export const list = async (limit: string, page: string) => {
    return await axiosInstance.get(`/tools//maintanance/list?page=${page}&limit=${limit}`);
};

export const get = async (id: string) => {
    return await axiosInstance.get(`/tools/maintanance/${id}`);
};
export const update = async (request: any) => {
    return await axiosInstance.put(`/tools/maintanance/return/`, request)
};
export const getDetails = async (id: string) => {
    return await axiosInstance.get(`/rent/getDetails/${id}`);
};

export const Delete = async (id:string) => {
    return await  axiosInstance.delete(`/tools/maintanance/${id}`);
  }
  export const multipleDelete = async (idList:any) => {
    return await  axiosInstance.post(`/tools/maintanance/deleteByIdList/`,idList);
  }

  export const searchTool = async (name:any) => {
    return await axiosInstance.get(`/tools/maintanance/search/${name}?page=${1}&limit=${10}`);
  };
