import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import './viewDetails.css'
import imageUnavailable from '../../../assets/thumb_image_not_available.png'
interface ProductProps {
    setVisible: any;
    visible: any;
    details: any | undefined;
    tittle: string | undefined;
    values: any;
}

const ViewDetailsV2: React.FC<ProductProps> = (props: ProductProps) => {
    return (
        <Dialog
            header={props.values.sl_no}
            visible={props.visible}
            style={{ width: '50vw' }}
            onHide={() => props.setVisible(false)}
        >
            <div >
                <div className="card__title">

                </div>
                <div className="card__body">
                    <div className="half">
                        <div className="image">
                            <img
                                src={imageUnavailable}
                                style={{ height: '150px', width: '150px' }}
                                alt=""
                            />
                        </div>
                        <div className="featured_text">
                            <h3 style={{ fontWeight: 'bold' }} className='capitalize'>{props.tittle}</h3>
                            <p className="category">{props.values?.category}</p>
                            {/* <p className="rent">₹{props.values?.rent}/{props.values?.rent_type}</p> */}
                            {props.values?.rent && (
                                <p className="rent">
                                    ₹{props.values.rent}/{props.values.rent_type}
                                </p>
                            )}
                        </div>

                    </div>
                    <div className="half">
                        <div className="description">

                            {props.details === null &&
                                <> <p className='description_heading'>Description</p>
                                    <p>{props.values.short_description}</p>

                                    {props.values.quantity &&
                                        <span className="stock">
                                            <p className='description_heading'>Quantity</p>
                                            {props.values.quantity + " " + "Nos"}
                                        </span>
                                    }
                                    
                                        <>
                                            <p className='mt-4 description_heading'>Measurements</p>
                                            <ul>
                                                {props.values.measurements?.map((measurement: any) => (
                                                    <li key={measurement._id} className='mt-2 capitalize'>
                                                        {measurement.magnitude} : {measurement.value} {measurement.unit}
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    
                                </>
                            }

                            {props.details &&
                                <div className="invoice-table-2 grid grid-nogutter">
                                    <div className="col-6">
                                        {Object.keys(props.details).map(key => (
                                            <div className="invoice-table-col header-col capitalize mt-2" key={key}>
                                                <span>{key}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-6">
                                        {Object.keys(props.details).map(key => (
                                            <div className="invoice-table-col content-col mt-2" key={key}>
                                                {key === 'Email' ? (
                                                    <span>{props.details[key]}</span>
                                                ) : (
                                                    <span className='capitalize'>{props.details[key]}</span>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }

                        </div>


                    </div>
                </div>
                <div className="card__footer justify-content-end">
                    <div className="action ">
                        <Button type="button" label="Close" className="p-button-raised p-button-rounded" onClick={() => props.setVisible(false)} />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ViewDetailsV2;
