import { axiosInstance } from '../interceptors/interceptors';



export const get = async (id:string) => {
    return await axiosInstance.get(`/users/getuser/${id}`);
};
export const getDetails = async (id:string) => {
    return await axiosInstance.get(`/users/getEmployee/${id}`);
};

export const update = async (id: string, request: any) => {
    return await axiosInstance.put(`/users/${id}`,request)
};