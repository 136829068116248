
import { TabMenu } from 'primereact/tabmenu';
import  { useState } from 'react';
import SerialNoList from './serialNo/serialNoList';
import { useTranslation } from 'react-i18next';
import CategoryList from './category/categoryList';
import RoleList from './roles/roleList';
import PermissionList from './permissions/permissionList';


const MasterView = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [t, i18n] = useTranslation()
    const items = [
        
        { label: t('Serial No'), icon: 'pi pi-fw pi-list' },
        { label: t('Category'), icon: 'pi pi-fw pi-list' },
        // { label: t('Agency'), icon: 'pi pi-fw pi-briefcase' },
        { label: t('Role'), icon: 'pi pi-fw pi-users' },
        { label: t('Permissions'), icon: 'pi pi-fw pi-lock' },
        // { label: t('Interest Rate'), icon: 'pi pi-fw pi-money-bill' },
        // { label: t('Loan Settings'), icon: 'pi pi-fw pi-cog' },
    ];

    return (
        <>
             <div className='flex mb-3'><i className="pi pi-cog" style={{ fontSize: '2rem' }}></i> <h2 className='ml-2'>Admin Panel</h2></div>
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => { setActiveIndex(e.index); }} className='master-tabmenu' />
            {activeIndex === 0 && <div className="mt-5">
                <SerialNoList />
            </div>}
            {activeIndex ===1  && <div className="mt-5">
                <CategoryList />
            </div>}
            {activeIndex ===2  && <div className="mt-5">
                <RoleList />
            </div>}
            {activeIndex ===3  && <div className="mt-5">
                <PermissionList/>
            </div>}
            
        </>
    );
};

export default MasterView;