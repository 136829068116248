import React, { useState, useEffect } from "react";
import DataGrid from "../../../components/datagrid/DataGrid";
import { itemsSvc, stockSvc, userSvc } from '../../../services';
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import StockModal from "./stockmodal";
import ActionButton from "../../../components/button/actionButton";
import SearchBar from "../../../components/common/searchbar/searchBar";
import ViewDetailsV2 from "../../../components/modal/viewDetails/viewDetailsV2";
import StockAddQuantity from "./stockAddQuantity";


const StockList: React.FC = () => {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, settotalRecords] = useState(0);
  const [page, setPage] = useState('1');
  const [limit, setLimit] = useState('10');
  const [visible, setVisble] = useState(false);
  const[values,setValues]=useState<any>('')
  const [id, setId] = useState<string>();
  const [t] = useTranslation()
  const [addQtyVissible, setAddQtyVissible]=useState<boolean>(false);
  const [quantity, setquantity] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState(''); //For serch bar
  const [viewDetails, setviewDetails] = useState<boolean>(false);

  const columns = [
    { field: 'sl_no', header: 'Item Id' },
    { field: 'tool_name', header: 'Tool Name' },
    { field: 'rent_type', header: 'Rent Type' },
    { field: 'rent', header: 'Rent amount' ,type:'amount'},
    { field: 'quantity', header: 'Total Quantity' },
    { field: 'available', header: 'Available' },
    { field: 'in_use', header: 'In Use' },
    { field: 'in_maintanance', header: 'In Maintanance' },
  ];


  const stockData = () => {
    stockSvc.list(String(limit), String(page)).then(res => {
      setTableData(res.data.data);
      settotalRecords(res.data.recordcount);
    });
  };

  useEffect(() => {
    stockData()
  }, [limit, page]);



  const getPaginationProps = (p: string, l: string) => {
    setPage((Number(p) + 1).toString());
    setLimit(l);
  };
  const onCreateOrUpdate = (i: string) => {
    setId(i);
    setVisble(true);
  };

  const details = (id: string) => {
    itemsSvc.get(id).then(res => {
      setValues(res.data);
    });
    setviewDetails(true);
  }
  const onHide = () => {
    setAddQtyVissible(false)
    setquantity(0)
  }





 

  const extraAction = ({ rowData }: any) => {
    // const hasViewPermission = permissionList && permissionList.includes('pages.user.get');
    // const hasUpdatePermission = permissionList && permissionList.includes('pages.user.update');
    // const hasDeletePermission = permissionList && permissionList.includes('pages.user.delete');
    return <>
      {  (<ActionButton label={t("View")+''} icon="pi pi-eye" className="p-button-secondary" onClick={()=>{ details(rowData.tool_id)}} />)}
      { (<ActionButton label={t("Update")+''} icon="pi pi-pencil" className="p-button-secondary" onClick={() => { onCreateOrUpdate(rowData.tool_id); }} />)}
      { (<ActionButton label={t("Add Qty")+''} icon="pi pi-plus" className="p-button-secondary" onClick={() => { setId(rowData.tool_id);setAddQtyVissible(true) }} />)}

    </>;
  };
      // Implement Search bar 
      const handleSearch = () => {
        stockSvc.searchTool(searchQuery).then(res => {
          if (res) {
            setTableData(res.data.data);
            settotalRecords(res.data.recordcount);
          } 
        });
    
      };
    
      const handleInputChange = (e: any) => {
        const value = e.target.value;
        setSearchQuery(value);
        if (value === '') {
          stockData()
        }
      };
      const stockSearch = () => {
    
        return (
          <React.Fragment>
            <SearchBar
              searchQuery={searchQuery}
              handleInputChange={handleInputChange}
              handleSearch={handleSearch}
            />
          </React.Fragment>
        );
      };

  return (
    <>
      <DataGrid tittle={t("Stocks") as string} data={tableData} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} onCreateOrEdit={onCreateOrUpdate}
        deleteByIdList={undefined} extraAction={extraAction} handleStatusUpdate={undefined}  searchBar={stockSearch}
        />

        {/* For save and update */}
         <StockModal id={id} setVisible={setVisble} visible={visible} updateTableData={stockData} viewOnly={false} />
         {/* For view stock details */}
         {viewDetails && <ViewDetailsV2  setVisible={setviewDetails} visible={viewDetails} details={null} tittle={values?.tool_name} values={values} />}
         {/* Add items Quantity */}
         <StockAddQuantity id={id} setVisible={setAddQtyVissible} visible={addQtyVissible} updateTableData={stockData} viewOnly={false} />

    </>
  );
};

export default StockList;