import React, { useRef, useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Paginator, PaginatorTemplateOptions } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { roleSvc } from '../../../../services';
import { useTranslation } from 'react-i18next';
import RoleModal from './roleModal';
import RolePermission from './rolePermission';
import ActionButton from '../../../../components/button/actionButton';
import Heading from '../../../../components/styledcomponents/heading';
import InputGroup from 'react-bootstrap/esm/InputGroup';

const RoleList = () => {
    const [page, setPage] = useState(0);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [globalFilter, setGlobalFilter] = useState('');
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [selected, setSelected] = useState([]);
    const [roleList, setRoles] = useState([]);
    const [totalRecords, setTotal] = useState(0);
    const [visible, setVisible] = useState(false);
    const [visiblePermission, setVisiblePermission] = useState(false);
    const [edit, setEdit] = useState(true);
    const [id, setId] = useState<string>('');
    const dt = useRef<DataTable<any>>(null);
    const [t, i18n] = useTranslation()

    const getRoles = () => {
        roleSvc.list((page + 1), limit).then((res: any) => {
            setRoles(res.data.roleList);
            setTotal(res.data.recordcount);
        });
    };

    
    const exportCSV = () => {
        if (dt.current)
            dt.current.exportCSV();
    };

    const deleteByIdList = (i: any) => {
        console.log(i)
        roleSvc.multipleDelete(i).then(res => {
            if (res) {
                toast.success('Role deleted successfully');
                getRoles()
                setId('')
                setDeleteDialog(false)
            } else {
                toast.error('Failed to delete Role');
                setDeleteDialog(false)
            }
        });
    }

    const multipleDelete = () => {
        const selectedIds = (selected as { _id: string }[]).map(item => item._id);
        if (selectedIds.length) {
            deleteByIdList({ idList: selectedIds });
        }
    };

    const deleteRecord = (i: string) => {
        setDeleteDialog(true);
        roleSvc.Delete(i).then(res => {
            if (res) {
                toast.success('Role deleted successfully');
                // updateTableData();
                getRoles()
                setDeleteDialog(false)
            } else {
                toast.error('Failed to delete Role');
                setDeleteDialog(false)
            }
        });
    }
    const actionBodyTemplate = (rowData: any) => {
        return (
            <>
                <ActionButton icon="pi pi-pencil" label={t("Edit") + ''} className="p-button-secondary" onClick={() => onEditOrView(true, rowData._id)} />
                <ActionButton icon="pi pi-plus" label={t("Add Permission") + ''} className="p-button-secondary" onClick={() => { setId(rowData._id); setVisiblePermission(true) }} />
                <ActionButton icon="pi pi-trash" label={t("Delete") + ''} className="p-button-secondary" onClick={() => { setDeleteDialog(true); setId(rowData._id) }} />

            </>
        );
    };
    const deleteDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { id ? deleteRecord(id) : multipleDelete() }} />
        </>
    );

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('Roles')}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e: any) => setGlobalFilter(e.target.value)} placeholder={t("Search") + "..."} />
            </span>
        </div>
    );
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="search-bar">
                    <InputGroup>

                        <InputText
                            // value={searchQuery}
                            placeholder="Search..."
                            style={{ width: '300px', height: '2.5rem' }}
                        />
                        <Button label={t("Search") + ''} icon="pi pi-search" style={{ backgroundColor: '#11B3CF', height: '2.5rem' }} className='p-button-success' />
                    </InputGroup>
                </div>
                <Button label={t("Delete") + ''} icon="pi pi-trash" className="p-button-danger p-button-sm" onClick={() => { setDeleteDialog(true); }} disabled={!selected || !selected.length} style={{ display: selected && selected.length >= 2 ? 'inline-block' : 'none' }} />

                {/* <Button label={t("delete")+''} icon="pi pi-trash" className="p-button-danger p-button-sm" onClick={() => { }} disabled={!selected || !selected.length} style={{ display: selected && selected.length>=2 ? 'inline-block' : 'none' }} /> */}
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label={t("Add New Role") + ''} icon="pi pi-plus" style={{ backgroundColor: '#11B3CF' }} className="p-button-success p-button-sm mr-2" onClick={() => { onEditOrView(false); }} />
                <Button icon="pi pi-file-excel" className="p-button-success p-button-sm" style={{ backgroundColor: '#11B3CF' }} onClick={exportCSV} />
            </React.Fragment>
        );
    };
    const paginatorTemp: PaginatorTemplateOptions = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 120, value: 120 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                        {t('Items per page') + ': '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options: { first: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; last: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; totalRecords: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };
    const onPageChange = (event: { first: React.SetStateAction<number>; rows: React.SetStateAction<number>; page: React.SetStateAction<number>; }) => {
        setFirst(event.first);
        setLimit(event.rows);
        setPage(event.page);
    };
    const onEditOrView = (edit: boolean, id?: string) => {
        setEdit(edit);
        setVisible(false);
        if (id)
            setId(id);
        setVisible(true);

    };
    useEffect(() => {
        getRoles();
    }, [page, limit]);
    useEffect(() => {
        if (!visible)
            setId('');
    }, [visible]);
    return (
        <React.Fragment>
            <div className="grid crud-demo">
                <div className="col-12">
                    <div ><Heading className="m-0">{t('Roles')}</Heading></div>
                    <div className="master-toolbar"><div>{leftToolbarTemplate()} </div><div>{rightToolbarTemplate()}</div></div>
                    <DataTable
                        ref={dt}
                        value={roleList}
                        selection={selected}
                        onSelectionChange={(e: any) => setSelected(e.value)}
                        dataKey="_id"
                        className="datatable-responsive master-datatable"
                        globalFilter={globalFilter}
                        emptyMessage="No data found."
                    // responsiveLayout="scroll"
                    >
                        <Column style={{ flexGrow: 1, flexBasis: '200px' }} selectionMode="multiple" headerStyle={{ width: '4rem' }}></Column>
                        <Column className="capitalize" style={{ flexGrow: 1, flexBasis: '200px' }} header={t("Name")} field='name' ></Column>
                        <Column header={t("Action")} style={{ textAlign: 'center', flexGrow: 1, flexBasis: '200px' }} alignHeader={'center'} body={actionBodyTemplate} ></Column>
                    </DataTable>
                    <Paginator template={paginatorTemp} first={first} rows={limit} totalRecords={totalRecords} onPageChange={onPageChange} className="justify-content-end my-3"></Paginator>

                    <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Are you sure you want to delete?</span>}

                        </div>
                    </Dialog>
                </div>
            </div>
            {visible && <RoleModal visible={visible} setVisible={setVisible} viewOnly={edit} id={id} updateTableData={getRoles} />}
            {visiblePermission && <RolePermission visible={visiblePermission} setVisible={setVisiblePermission} id={id} />}
        </React.Fragment>
    );
};

export default RoleList;