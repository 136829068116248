import React from 'react';
import { Button } from 'primereact/button';

const ActionButton = ({ label, icon, onClick, className }:any) => {
  const buttonClassName = ` ${className} p-button-text mr-2`;

  return (
    <Button
      label={label}
      icon={icon}
      style={{ fontSize: '15px', padding: '1px 8px' }}
      className={buttonClassName}
      onClick={onClick}
    />
  );
};

export default ActionButton;
