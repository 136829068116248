import React from 'react';

import '../../../styles/css/sidebar.css';
import { MenuProvider } from '../../../context/menucontext';
import { model } from './data';
import AppMenuitem from './AppMenuitem';
import './sidebar.css'
const Sidebar = () => {

    // const isExpanded = useSelector((state: any) => (state.sidebar.isExpanded));


    return(
        <MenuProvider>
        <ul className="layout-menu">
            {model.map((item, i) => {
                return !item.seperator ? <AppMenuitem item={item} root={true} index={i} key={item.items} /> : <li className="menu-separator"></li>;
            })}

          
        </ul>
    </MenuProvider>
    )


};
export default Sidebar;