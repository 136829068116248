import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import logo from '../../../assets/comptivia-newlogo.png'
import { Form, Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../../services';
import ErrorMessage from '../../../components/common/ErrorMessage';
import { loginValidation } from '../../../validations';
import { AuthContext } from '../../../context';
import { useContext } from 'react';
import { fetchUserPermissionsRequest } from '../../../redux/actions/permissionActions';
import { setCookie } from '../../../utils/cookieUtils';


const LoginPage = () => {
    const dispatch = useDispatch();
    const { setIsAuthenticated } = useContext(AuthContext)
    const navigate = useNavigate();
    const initialValues = { user_name: '', password: '' };
    const onSubmit = async (e: any, actions: FormikHelpers<{
        user_name: string;
        password: string;
    }>) => {
        const res = await login(e);
        if (res) {
            setCookie('user', JSON.stringify(res.data));
            dispatch(fetchUserPermissionsRequest(res.data.user.id));
            setIsAuthenticated(true);
            setCookie('isAuthenticated', 'true');
            navigate('/app');
        }
    };

    const containerClassName = classNames('surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden',);


    return (
        <Formik initialValues={initialValues} validationSchema={loginValidation} onSubmit={onSubmit}>
            {
                ({ isSubmitting, errors, getFieldProps, touched, isValid }) => (<Form>
                    <div className={containerClassName}>
                        <div className="flex flex-column align-items-center justify-content-center">
                            <div style={{ borderRadius: '56px', padding: '0.3rem', background: 'linear-gradient(180deg, #11B3CF 10%, rgba(33, 150, 243, 0) 30%)' }}>
                                <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                                    <div className="text-center mb-5">
                                        <img src={logo} alt="logo" height="50" className="mb-3" />
                                        <div className="text-900 text-3xl font-medium mb-3">Welcome to RMT!</div>
                                        <span className="text-600 font-medium">Sign in to continue</span>
                                    </div>

                                    <div className='auth-form'>
                                        <div className="flex flex-column gap-2 mb-3">
                                            <label htmlFor="email1" className="auth-label ">
                                                <i className="pi pi-user mr-2"></i>
                                                Username
                                            </label>
                                            <InputText id="email1" aria-describedby='username-help' type="text" className={`w-full md:w-30rem ${touched.user_name && errors.user_name ? 'p-invalid' : ''}`}  {...getFieldProps(('user_name'))} name='user_name' placeholder='Username' />
                                            {touched.user_name && errors.user_name && <ErrorMessage message={errors.user_name} id="username-help" />}
                                        </div>
                                        <div className="flex flex-column gap-2 ">
                                            <label htmlFor="password1" className="auth-label">
                                                <i className="pi pi-key mr-2"></i>
                                                Password
                                            </label>
                                            <Password id="password1" aria-describedby='password-help' className={`w-full ${touched.password && errors.password ? 'p-invalid' : ''}`} inputClassName='w-full md:w-30rem' feedback={false}  {...getFieldProps(('password'))} name='password' toggleMask placeholder='Password' />
                                            {touched.password && errors.password && <ErrorMessage message={errors.password} id="password-help" />}
                                        </div>
                                        <div className="flex align-items-center justify-content-between mb-5 gap-5">
                                            <Link to="/forgotpassword" className="font-medium no-underline ml-auto text-right cursor-pointer mt-2" style={{color:'#11B3CF'}}>Forgot Password?</Link>
                                        </div>
                                        <Button label={isSubmitting ? '' : 'Login'} className="w-full p-2 text-md auth-login-button" type='submit' loading={isSubmitting} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>)

            }
        </Formik>
    );
};


export default LoginPage;