import { Form, Formik, FormikHelpers } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import {  useState } from "react";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/common/ErrorMessage";
import { stockSvc, } from "../../../services";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import CancelButton from "../../../components/button/cancelButton";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { stockAddQuantity } from "../../../validations";


const StockAddQuantity = ({ setVisible, visible = false, viewOnly = true, id,updateTableData }: IStockQuantity) => {
    const initial:IStockAddQuantity = {
        quantity: 0,
        purchased_on:'',
        cost:0,
    };
    const [initialValues, setInitialValues] = useState<IStockAddQuantity>(initial);
    const [t] = useTranslation()
    const [itemId, setItemId] = useState<string |undefined>(id);


    const onSubmit = async (e:IStockAddQuantity,formikHelpers: FormikHelpers<IStockAddQuantity>) => {
        const req={...e,tool_id:id}
        const res = await stockSvc.addStock(req)
        toast.success("Success");
        updateTableData()
        setInitialValues(initial)
        setVisible(false);
    };

    const onHide = () => {
        setInitialValues(initial);
        setItemId(undefined);
        setVisible(false);
    };
    const DialogFooter = (
        <div className="flex flex-wrap justify-content-end gap-3">
            <CancelButton label={t("Cancel") + ''} onClose={onHide} />
            <Button label={id ? t("Update") as string : t("Save") as string} style={{ background: '#11B3CF' }} icon="pi pi-check" className="p-button-success p-button-raised" type='submit' />
        </div>);
    return (
        <Dialog header={t("Add Stock")} visible={visible} maximizable style={{ width: '40vw' }} onHide={onHide}>
            <Formik enableReinitialize initialValues={initialValues} validationSchema={stockAddQuantity} onSubmit={onSubmit}>
                {({ values, errors, getFieldProps, touched, setFieldValue }) => (
                    <Form>

                        <div className="grid p-fluid mt-3">

                        <div className="field col-12 md:col-4">
                                <label htmlFor="Quantity">Quantity</label>
                                    <InputNumber id="quantity" onChange={(e) => setFieldValue('quantity', e.value)} value={values.quantity || 0} name='quantity'/>
                                {touched.quantity && errors.quantity && <ErrorMessage message={errors.quantity} id="quantity" />}
                            </div>

                            <div className="field col-12 md:col-4">
                                <label htmlFor="cost" className="label-raleway-font">{t("Cost")}</label>
                                <InputNumber id="cost" minFractionDigits={2} maxFractionDigits={2} onChange={(e) => setFieldValue('cost', e.value)} value={values.cost || 0} name='cost' />
                                {touched.cost && errors.cost && <ErrorMessage message={errors.cost} id="cost" />}
                            </div>

                            <div className="field col-12 md:col-4">
                                <label htmlFor="purchased_on" className="label-raleway-font">{t("Purchased on")}</label>
                                <Calendar
                                    id="purchased_on"
                                    name="purchased_on"
                                    value={values.purchased_on}
                                    onChange={(e) => setFieldValue('purchased_on', new Date(e.value as Date))}
                                    dateFormat="dd/mm/yy"
                                    showIcon
                                    className="w-full" />
                                {touched.purchased_on && errors.purchased_on && <ErrorMessage message={errors.purchased_on} id="purchased_on" />}
                            </div>

                        </div>
                        {DialogFooter}
                    </Form>)}
            </Formik>

        </Dialog>
    );
};

export default StockAddQuantity;

interface IStockQuantity {
    visible: boolean;
    setVisible(val: boolean): void;
    viewOnly: boolean,
    id?: string;
    updateTableData: any
}

interface IStockAddQuantity  {
    quantity: number,
    purchased_on:string,
    cost:number,
};