import React, { useState, useEffect } from "react";
import DataGrid from "../../../components/datagrid/DataGrid";
import { rentSvc, userSvc } from '../../../services';
import RentModal from "./rentModal";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";
import ViewDetailsModal from "../../../components/modal/viewDetails/viewDetails";
import ActionButton from "../../../components/button/actionButton";
import SearchBar from "../../../components/common/searchbar/searchBar";




const RentList: React.FC = () => {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, settotalRecords] = useState(0);
  const [page, setPage] = useState('1');
  const [limit, setLimit] = useState('10');
  const [visible, setVisble] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [id, setId] = useState<string | undefined>();
  const [t] = useTranslation()
  const[rentData,setRentData]=useState<any>('')
  const[toolData,setToolData]=useState<any>('')
  const [viewDetails, setviewDetails] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState(''); //For serch bar
  const columns = [
    { field: 'rent_no', header: 'Rent No' },
    { field: 'customer_id.first_name', header: 'Customer Name' },
    {
      field: 'status', header: 'Status', type:'badgestatus', status: [
      { key: 'rented', value: 'success' },
      { key: 'partially returned', value: 'info' },
      { key: 'returned', value: 'danger' },
      ]
    }
  ];


  const rentList = () => {
    rentSvc.list(String(limit), String(page)).then(res => {
      setTableData(res.data.data);
      settotalRecords(res.data.recordcount);
    });
  };

  useEffect(() => {
    rentList()
  }, [limit, page]);

  const handleStatusUpdate = (e: any, id: string,newStatus:any) => {
    // const newStatus = e.target.value;
    userSvc.statusUpdate(id, newStatus).then(res => {
      rentList();
    });
  };

  const getPaginationProps = (p: string, l: string) => {
    setPage((Number(p) + 1).toString());
    setLimit(l);
  };
  const onCreateOrUpdate = (i: string) => {
    setId(i);
    setVisble(true);
  };

  const details = (id: string) => {
    rentSvc.getDetails(id).then(res => {
      setRentData(res.data.rentData);
      setToolData(res.data.toolData)
    });
    setviewDetails(true);
  }
// To show rented details  in view Details component

  const rentDetails: any = {
    "Rent Id": rentData.rent_no?rentData.rent_no:"N/A",
    "Customer Name": rentData? `${rentData.customer_id.first_name}  ${rentData.customer_id.last_name} `: "N/A",
    
};

 // To show rented items details  in view Details component

const toolDataColumns = [
  { field: 'tool_id.tool_name', header: 'Item Name' },
  { field: 'quantity', header: 'Rented Qty' },
  { field: 'started_date', header: 'Rented Date',type:'date' },
  { field: 'returned_quantity', header: 'Returned Qty' },
  // { field: 'returned_date', header: 'Returned Date',type:'date' },


  // { field: 'phone', header: 'phone' },
  {
    field: 'status', header: 'Status', type:'status', status: [
      { key: 'rented', value: 'success' },
      { key: 'partially returned', value: 'info' },
      { key: 'returned', value: 'danger' },
    ]
  }
];



  const permissionList = useSelector((state: any) => state.permissions.permissions);

  const deleteRecord = (i: string) => {
    rentSvc.Delete(i).then(res => {
      if (res) {
        toast.success('Deleted successfully');
        rentList();
      } else {
        toast.error('Failed to delete user');
      }
    });
  }

  const deleteByIdList = (i: any) => {
    rentSvc.multipleDelete(i).then(res => {
      if (res) {
        toast.success('Deleted successfully');
        rentList();
      } else {
        toast.error('Failed to delete');
      }
    });
  }
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { deleteRecord(id ?? " "); setDeleteDialog(false) }} />
    </>
  );
  const extraAction = ({ rowData }: any) => {
    const hasViewPermission = permissionList && permissionList.includes('pages.admin.rent.get');
    const hasUpdatePermission = permissionList && permissionList.includes('pages.admin.rent.update');
    const hasDeletePermission = permissionList && permissionList.includes('pages.admin.rent.delete');
    return <>
      { hasViewPermission && (<ActionButton label={t("View")+''} icon="pi pi-eye" className="p-button-secondary" onClick={() => { details(rowData._id) }} />)}
      {hasUpdatePermission&& rowData.status !=="returned" && (<ActionButton label={t("Add Rent")+''} icon="pi pi-plus"  className="p-button-secondary" onClick={() => { onCreateOrUpdate(rowData._id); }} />)}
      { hasDeletePermission && rowData.status ==="returned" && (<ActionButton label={t("Delete")+''} icon="pi pi-trash"  className="p-button-secondary" onClick={() => { setDeleteDialog(true); setId(rowData._id) }} />)}
    </>;
  };

        // Implement Search bar 
        const handleSearch = () => {
          rentSvc.searchRent(searchQuery).then(res => {
            if (res) {
              setTableData(res.data.rentData);
              settotalRecords(res.data.count);
            } 
          });
      
        };
      
        const handleInputChange = (e: any) => {
          const value = e.target.value;
          setSearchQuery(value);
          if (value === '') {
            rentList()
          }
        };
        const rentSearch = () => {
      
          return (
            <React.Fragment>
              <SearchBar
                searchQuery={searchQuery}
                handleInputChange={handleInputChange}
                handleSearch={handleSearch}
              />
            </React.Fragment>
          );
        };
        
  return (
    <>
      <DataGrid tittle={t("Rent") as string} data={tableData} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} onCreateOrEdit={onCreateOrUpdate}
        deleteByIdList={deleteByIdList} extraAction={extraAction} handleStatusUpdate={handleStatusUpdate} searchBar={rentSearch}/>
      <RentModal id={id} setVisible={setVisble} visible={visible} updateTableData={rentList} viewOnly={false} searchBar={rentSearch} />
      <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {!id ? <span>Are you sure you want to delete the selected items?</span> : <span>Are you sure you want to delete ?</span>}
        </div>
      </Dialog>
      {/* {id &&<ReturnModal id={id} setVisible={setVisble} visible={visible} updateTableData={rentList} viewOnly={false}  />} */}

      
      {viewDetails && <ViewDetailsModal  setVisible={setviewDetails} visible={viewDetails} details={rentDetails} tittle={rentDetails.Name} tableData={toolData} tableDataColumns={toolDataColumns} extraAction={undefined}/>}

    </>
  );
};

export default RentList;