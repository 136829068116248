

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_USER_PERMISSIONS_REQUEST,
  fetchUserPermissionsSuccess,
  fetchUserPermissionsFailure,
} from '../actions/permissionActions';
import { permissionsSvc } from '../../services';

function* fetchUserPermissions(action) {
  try {
    const userId = action.payload;
    const response = yield call(permissionsSvc.getUserPermissions,userId);
    const permissions = response.data
    yield put(fetchUserPermissionsSuccess(permissions));
  } catch (error) {
    yield put(fetchUserPermissionsFailure(error));
  }
}


function* watchFetchUserPermissions() {
  yield takeLatest(FETCH_USER_PERMISSIONS_REQUEST, fetchUserPermissions);
}

export default function* rootSaga() {
    yield call(watchFetchUserPermissions);
}
