import React, { useState, useEffect } from "react";
import DataGrid from "../../../components/datagrid/DataGrid";
import { rentSvc, userSvc } from '../../../services';
import ReturnModal from "./returnModal";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import ViewDetailsModal from "../../../components/modal/viewDetails/viewDetails";
import Invoice from "./invoice";
import { useSelector } from "react-redux";
import ActionButton from "../../../components/button/actionButton";
import SearchBar from "../../../components/common/searchbar/searchBar";


const RentList: React.FC = () => {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, settotalRecords] = useState(0);
  const [page, setPage] = useState('1');
  const [limit, setLimit] = useState('10');

  //For Bill Pagination
  const [totalBillRecords, settotalBillRecords] = useState(0);
  const [billPage, setBillPage] = useState('1');
  const [billLimit, setBillLimit] = useState('10');

  const [visible, setVisble] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [id, setId] = useState<string | undefined>();
  const [billId, setBillId] = useState<string>('id');
  const [rentId, setRentId] = useState<string>('');// to show rent id in bill list page
  const [viewBill, setViewBill] = useState(false);
  const [PayBill, setPayBill] = useState(false);
  const [t] = useTranslation()
  const[rentData,setRentData]=useState<any>('')
  const[billData,setBillData]=useState<any>('')
  const [viewDetails, setviewDetails] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState(''); //For serch bar
  const columns = [
    { field: 'rent_no', header: 'Rent No' },
    { field: 'customer_id.first_name', header: 'Customer Name' },
    {
      field: 'status', header: 'Status', type:'badgestatus', status: [
        { key: 'rented', value: 'success' },
        { key: 'partially returned', value: 'info' },
        { key: 'returned', value: 'danger' },
      ]
    }
  ];


  const returnData = () => {
    rentSvc.list(String(limit), String(page)).then(res => {
      setTableData(res.data.data);
      settotalRecords(res.data.recordcount);
    });
  };

  useEffect(() => {
    returnData()
  }, [limit, page]);

  const handleStatusUpdate = (e: any, id: string,newStatus:any) => {
    userSvc.statusUpdate(id, newStatus).then(res => {
      returnData();
    });
  };

  const getPaginationProps = (p: string, l: string) => {
    setPage((Number(p) + 1).toString());
    setLimit(l);
  };

  //For Bill Pagination
  const getBillPaginationProps = (p: string, l: string) => {
    setBillPage((Number(p) + 1).toString());
    setBillLimit(l);
  };

  const onCreateOrUpdate = (i: string) => {
    setId(i);
    setVisble(true);
  };

  const details = (id: string) => {
    rentSvc.billList(String(billLimit), String(billPage),id).then(res => {
      setBillData(res.data.data)
      setRentId(res.data.rent_id)
      settotalBillRecords(res.data.recordcount);
    });
    setviewDetails(true);
  }

// To show rented details  in view Details component

  const rentDetails: any = {
    "Rent Id": rentId?rentId:"N/A",
};

 // To show rented items details  in view Details component

const toolDataColumns = [
  
  { field: 'generated_at', header: 'Invoice Date',type:'date' },
  { field: 'paid_on', header: 'Paid On',type:'date'  },
  { field: 'rent_amnt', header: 'Bill Amount',type:'amount' },
  {
    field: 'status', header: 'Status',type:'status', status: [
      { key: 'rented', value: 'success' },
      { key: 'partially returned', value: 'info' },
      { key: 'returned', value: 'danger' },
    ]
  }
];



  const permissionList = useSelector((state: any) => state.permissions.permissions);

  const deleteRecord = (i: string) => {
    rentSvc.Delete(i).then(res => {
      if (res) {
        toast.success('Deleted successfully');
        returnData();
      } else {
        toast.error('Failed to Delete');
      }
    });
  }

  const deleteByIdList = (i: any) => {
    rentSvc.multipleDelete(i).then(res => {
      if (res) {
        toast.success('Deleted successfully');
        returnData();
      } else {
        toast.error('Failed to delete');
      }
    });
  }
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { deleteRecord(id ?? " "); setDeleteDialog(false) }} />
    </>
  );
  const extraAction = ({ rowData }: any) => {
    const hasReturnPermission = permissionList && permissionList.includes('pages.admin.rent.return');
    const hasBillsPermission = permissionList && permissionList.includes('pages.admin.bill.list');
    const hasDeletePermission = permissionList && permissionList.includes('pages.admin.rent.delete');
    return <>
      {hasReturnPermission &&rowData.status !=="returned" && <ActionButton label={t("Return")+''} icon="pi pi-replay" className="p-button-secondary" onClick={() => { setId(rowData._id);setVisble(true) }} />}
      {hasBillsPermission && rowData.status !=="rented" && (<ActionButton label={t("Bills")+''} icon="pi pi-book" className="p-button-secondary" onClick={() => { details(rowData._id) }} />)}
      {hasDeletePermission && rowData.status ==="returned" && (<ActionButton label={t("Delete")+''} icon="pi pi-trash" className="p-button-secondary" onClick={() => { setDeleteDialog(true); setId(rowData._id) }} />)}
    </>;
  };

  const billExtraAction = ({ rowData }: any) => {
    
    return <>
        {rowData.status ==="unpaid" && <ActionButton label={t("Pay Bill")+''} icon="pi pi-money-bill" className="p-button-secondary" onClick={() => { setBillId(rowData._id);setPayBill(true);setviewDetails(false) }} />}
        {  (<ActionButton label={t("View Bill")+''} icon="pi pi-book" className="p-button-secondary" onClick={() => {setBillId(rowData._id); setViewBill(true) }}  />)}
    </>;
  };

        // Implement Search bar 
        const handleSearch = () => {
          rentSvc.searchRent(searchQuery).then(res => {
            if (res) {
              setTableData(res.data.rentData);
              settotalRecords(res.data.count);
            } 
          });
      
        };
      
        const handleInputChange = (e: any) => {
          const value = e.target.value;
          setSearchQuery(value);
          if (value === '') {
            returnData()
          }
        };
        const returnSearch = () => {
      
          return (
            <React.Fragment>
              <SearchBar
                searchQuery={searchQuery}
                handleInputChange={handleInputChange}
                handleSearch={handleSearch}
              />
            </React.Fragment>
          );
        };
  return (
    <>
      <DataGrid tittle={t("Return & Bills") as string} data={tableData} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} onCreateOrEdit={onCreateOrUpdate}
        deleteByIdList={deleteByIdList} extraAction={extraAction} handleStatusUpdate={handleStatusUpdate} searchBar={returnSearch}
        hideButtons={true} heading="Return & Bills"// Add this prop to hide the buttons an show the heading
        />
      <Dialog visible={deleteDialog} style={{ width: '500px' }} header="Confirm" modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {!id ? <span>Are you sure you want to delete the selected items?</span> : <span>Are you sure you want to delete ?</span>}
        </div>
      </Dialog>

      {visible &&<ReturnModal rent_Id={id} setVisible={setVisble} visible={visible} updateTableData={returnData} viewOnly={false}  />}
      {viewDetails && <ViewDetailsModal  setVisible={setviewDetails} visible={viewDetails} details={rentDetails} tittle={rentDetails.Name} tableData={billData} tableDataColumns={toolDataColumns} extraAction={billExtraAction} totalRecords={totalBillRecords} paginationProps={getBillPaginationProps}/>}

      {PayBill &&<ReturnModal bill_Id={billId} setVisible={setPayBill} visible={PayBill} updateTableData={returnData} viewOnly={false}  />}
      {viewBill&&<Invoice id={billId} setVisible={setViewBill} visible={viewBill} />}
    </>
  );
};

export default RentList;