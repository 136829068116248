
import { axiosInstance } from '../interceptors/interceptors';


export const save = async (request: any) => {
  return await axiosInstance.post(`/tools/`, request);
};

export const list = async (limit: string, page: string) => {
  return await axiosInstance.get(`/tools/list?page=${page}&limit=${limit}`);
};

export const get = async (id:string) => {
  return await axiosInstance.get(`/tools/${id}`);
};
export const getAvailableMagnitudes = async () => {
  return await axiosInstance.get(`/master/getAvailableMagnitudes`);
};
export const getAll = async () => {
  return await axiosInstance.get(`/tools/getAll`);
};
export const getAutoSuggest = async (name:string) => {
  return await axiosInstance.post(`/tools/getAutoSuggest`,{name});
};

export const getAutoSuggestForRent = async (name:string) => {
  return await axiosInstance.post(`/tools/getAutoSuggestForRent`,{name});
};

export const getAutoSuggestById = async (id:string) => {
  return await axiosInstance.get(`/tools/getAutoSuggestById?id=${id}`);
};

export const update = async (id: string, request: any) => {
  return await axiosInstance.put(`/tools/${id}`,request)
};

export const Delete = async (id:string) => {
  return await  axiosInstance.delete(`/tools/${id}`);
}
export const multipleDelete = async (idList:any) => {
  return await  axiosInstance.post(`/tools/deleteByIdList/`,idList);
}

export const searchTool = async (name:any) => {
  return await axiosInstance.get(`/tools/search?name=${name}`);
};

