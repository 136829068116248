import { FETCH_USER_PERMISSIONS_SUCCESS } from '../actions/permissionActions';

const initialState = {
  permissions: null,
};

const permissionsReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case FETCH_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      return state;
  }
};

export default permissionsReducer;

