import { createContext, useState, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserPermissionsRequest } from '../redux/actions/permissionActions';
import { getCookie, setCookie } from '../utils/cookieUtils';

type AuthContextType = {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
};

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
});

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(getCookie('isAuthenticated') === 'true');

  const dispatch = useDispatch();

  useEffect(() => {
    const user = getCookie('user');
    if (user) {
      const data = user;
      const userId = data.user.id;
      dispatch(fetchUserPermissionsRequest(userId));
    }
  }, [dispatch]);

  const handleSetIsAuthenticated = (value: boolean) => {
    setIsAuthenticated(value);
    setCookie('isAuthenticated', value.toString());
  };

  const authContextValue = { isAuthenticated, setIsAuthenticated: handleSetIsAuthenticated };

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

