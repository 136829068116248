import { axiosInstance } from "../interceptors/interceptors";

export const getDashboardDetails = async () => {
    return await axiosInstance.get(`/rent/getDashboardDetails`);
  };

export const getChartDetails = async (request:any) => {
    return await axiosInstance.post(`/rent/getChartdetails`,request);
  };

  export const returnPendinglist = async (month:number) => {
    return await axiosInstance.get(`/rent/getpendingReturns?month=${month}`);
  };
  