import React, { useState, useEffect } from "react";
import DataGrid from "../../../components/datagrid/DataGrid";
import { maintenanceSvc, rentSvc, userSvc } from '../../../services';
import MaintenanceModal from "./maintenanceModal";
import { toast } from "react-toastify";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";
import ViewDetailsModal from "../../../components/modal/viewDetails/viewDetails";
import moment from "moment";
import ActionButton from "../../../components/button/actionButton";
import SearchBar from "../../../components/common/searchbar/searchBar";
import ViewDetailsV2 from "../../../components/modal/viewDetails/viewDetailsV2";




const MaintenanceList: React.FC = () => {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, settotalRecords] = useState(0);
  const [page, setPage] = useState('1');
  const [limit, setLimit] = useState('10');
  const [visible, setVisble] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [id, setId] = useState<string | undefined>();
  const [t] = useTranslation()
  const[maintenanceDetails,setMaintenanceDetails]=useState<any>('')
  const [viewDetails, setviewDetails] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState(''); //For serch bar
  const columns = [
    { field: 'sl_no', header: 'Sl No' },
    { field: 'tool_id.tool_name', header: 'Tool Name' },
    { field: 'quantity', header: 'Quantity' },
    {
      field: 'status', header: 'Status', type:'badgestatus', status: [
      { key: 'rented', value: 'success' },
      { key: 'partially returned', value: 'info' },
      { key: 'returned', value: 'danger' },
      ]
    }
  ];


  const maintenanceDataList = () => {
    maintenanceSvc.list(String(limit), String(page)).then(res => {
      setTableData(res.data.data);
      settotalRecords(res.data.recordcount);
    });
  };

  useEffect(() => {
    maintenanceDataList()
  }, [limit, page]);

  const handleStatusUpdate = (e: any, id: string,newStatus:any) => {
    userSvc.statusUpdate(id, newStatus).then(res => {
      maintenanceDataList();
    });
  };

  const getPaginationProps = (p: string, l: string) => {
    setPage((Number(p) + 1).toString());
    setLimit(l);
  };
  const onCreateOrUpdate = (i: string) => {
    setId(i);
    setVisble(true);
  };

  const details = (id: string) => {
    maintenanceSvc.get(id).then(res => {
      setMaintenanceDetails(res.data);
    });
    setviewDetails(true);
  }

// To show rented details  in view Details component

const maintenanceData: any = {
  "Maintenance Id": maintenanceDetails.sl_no || "N/A",
  "Item Name": maintenanceDetails.tool_id?.tool_name || "N/A",
  "Quantity": maintenanceDetails.quantity || "N/A",
  "Amount": maintenanceDetails.amount || "N/A",
  "Service Date":maintenanceDetails.service_date ? moment(maintenanceDetails.service_date).format('ll') : 'N/A',
  "Returned": maintenanceDetails.returned !== null ? maintenanceDetails.returned : 0,
  "Lost": maintenanceDetails.lost !== null ? maintenanceDetails.lost : "N/A",
  "Status": maintenanceDetails.status || "N/A",
};

 




//   const permissionList = useSelector((state: any) => state.permissions.permissions);

  const deleteRecord = (i: string) => {
    maintenanceSvc.Delete(i).then(res => {
      if (res) {
        toast.success('Deleted successfully');
        maintenanceDataList();
      } else {
        toast.error('Failed to delete user');
      }
    });
  }

  const deleteByIdList = (i: any) => {
    maintenanceSvc.multipleDelete(i).then(res => {
      if (res) {
        toast.success('Deleted successfully');
        maintenanceDataList();
      } else {
        toast.error('Failed to delete');
      }
    });
  }

  
  const deleteDialogFooter = (
    <>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => { deleteRecord(id ?? " "); setDeleteDialog(false) }} />
    </>
  );
  const extraAction = ({ rowData }: any) => {
    // const hasViewPermission = permissionList && permissionList.includes('pages.user.get');
    // const hasUpdatePermission = permissionList && permissionList.includes('pages.user.update');
    // const hasDeletePermission = permissionList && permissionList.includes('pages.user.delete');
    return <>
      {  (<ActionButton label={t("View")+''} icon="pi pi-eye" className="p-button-secondary " onClick={() => { details(rowData._id) }} />)}
      { rowData.status !=="returned" && (<ActionButton icon="pi pi-replay" label={t("Return")+''} className="p-button-secondary" onClick={() => { onCreateOrUpdate(rowData._id); }} />)}
      { rowData.status ==="returned" && (<ActionButton icon="pi pi-trash" label={t("Delete")+''} className="p-button-danger" onClick={() => { setDeleteDialog(true); setId(rowData._id) }} />)}
    </>;
  };
    // Implement Search bar 
    const handleSearch = () => {
      maintenanceSvc.searchTool(searchQuery).then(res => {
        if (res) {
          setTableData(res.data.data);
          settotalRecords(res.data.recordcount);
        } 
      });
  
    };
  
    const handleInputChange = (e: any) => {
      const value = e.target.value;
      setSearchQuery(value);
      if (value === '') {
        maintenanceDataList()
      }
    };
    const mainTananceSearch = () => {
  
      return (
        <React.Fragment>
          <SearchBar
            searchQuery={searchQuery}
            handleInputChange={handleInputChange}
            handleSearch={handleSearch}
          />
        </React.Fragment>
      );
    };
    
  return (
    <>
      <DataGrid tittle={t("Maintenance") as string} data={tableData} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} onCreateOrEdit={onCreateOrUpdate}
        deleteByIdList={deleteByIdList} extraAction={extraAction} handleStatusUpdate={handleStatusUpdate} searchBar={mainTananceSearch}/>
      <MaintenanceModal id={id} setVisible={setVisble} visible={visible} updateTableData={maintenanceDataList} viewOnly={false} />
      <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {!id ? <span>Are you sure you want to delete the selected items?</span> : <span>Are you sure you want to delete ?</span>}
        </div>
      </Dialog>
      {/* {visible &&<MaintenanceModal id={id} setVisible={setVisble} visible={visible} updateTableData={maintenanceData} viewOnly={false}  />} */}

      {viewDetails && <ViewDetailsV2 setVisible={setviewDetails} visible={viewDetails} details={maintenanceData} tittle={maintenanceDetails.tool_id?.tool_name} values={maintenanceDetails}  />}

      {/* {viewDetails && <ViewDetailsModal setVisible={setviewDetails} visible={viewDetails} details={maintenanceDeta} tittle={maintenanceDetails.RentId} tableData={undefined} tableDataColumns={undefined} extraAction={undefined} />} */}

    </>
  );
};

export default MaintenanceList;